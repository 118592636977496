import { SideBar as RoeSideBar } from '@dabapps/roe';
import React from 'react';
import { connect } from 'react-redux';

import { closeSideBar } from '^/navigation/actions';
import Nav from '^/navigation/components/nav';
import { StoreState } from '^/store/types';

interface DispatchProps {
  onClickCloseSideBar: typeof closeSideBar;
}

type StateProps = Pick<StoreState, 'sideBarOpen'>;

type Props = StateProps & DispatchProps;

const SideBar: React.FunctionComponent<Props> = React.memo(
  ({ sideBarOpen, onClickCloseSideBar }) => (
    <RoeSideBar
      position="left"
      open={sideBarOpen}
      onClickOutside={onClickCloseSideBar}
    >
      <Nav />
    </RoeSideBar>
  )
);

const mapStateToProps = ({ sideBarOpen }: StoreState): StateProps => {
  return {
    sideBarOpen,
  };
};

const mapDispatchToProps = {
  onClickCloseSideBar: closeSideBar,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
