import { Container, NavBar as RoeNavBar } from '@dabapps/roe';
import { faBars } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { toggleSideBar } from '^/navigation/actions';
import Nav from '^/navigation/components/nav';
import Logo from '^/logo';
import { StoreState } from '^/store/types';

type StateProps = Pick<StoreState, 'currentUser'>;

interface DispatchProps {
  onClickMenuIcon: typeof toggleSideBar;
}

type Props = StateProps & DispatchProps;

const NavBar: React.FunctionComponent<Props> = React.memo(
  ({ currentUser, onClickMenuIcon }) => {
    const lawFirmLogo = currentUser?.lawfirm_logo?.file_path;
    const [logoError, setLogoError] = useState(false);

    const onLogoError = useCallback(() => {
      setLogoError(true);
    }, [setLogoError]);

    useEffect(() => {
      setLogoError(false);
    }, [lawFirmLogo]);

    const hasLawFirmLogo = !!lawFirmLogo && !logoError;

    return (
      <RoeNavBar>
        <Container>
          <h1 className="font-size-large menu-item">
            <Link to="/">
              <Logo className="logo" />
            </Link>
          </h1>
          {hasLawFirmLogo && (
            <div className="lawfirm-logo-wrapper menu-item">
              <img
                className="lawfirm-logo"
                src={lawFirmLogo}
                onError={onLogoError}
              />
            </div>
          )}
          <Nav className="display-none md-display-block text-align-right menu-item" />
          <a
            className="font-size-large md-display-none menu-item burger-menu-btn"
            onClick={onClickMenuIcon}
          >
            <FontAwesomeIcon icon={faBars} />
          </a>
        </Container>
      </RoeNavBar>
    );
  }
);

const mapStateToProps = ({ currentUser }: StoreState): StateProps => {
  return {
    currentUser,
  };
};

export default connect(mapStateToProps, { onClickMenuIcon: toggleSideBar })(
  NavBar
);
