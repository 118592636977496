import { AppRoot } from '@dabapps/roe';
import React from 'react';
import { connect } from 'react-redux';
import {
  RouteComponentProps,
  withRouter,
  Route,
  Switch,
} from 'react-router-dom';

import { StoreState } from '^/store/types';
import UI from './ui';
import Export from './export';

type StateProps = Pick<StoreState, 'currentUser'>;

type RouteProps = RouteComponentProps<{}>;

type Props = RouteProps & StateProps;

const App: React.FunctionComponent<Props> = React.memo(props => (
  <AppRoot>
    <Switch>
      {props.currentUser && <Route path="/export" component={Export} />}
      <Route path="/" component={UI} />
    </Switch>
  </AppRoot>
));

const mapStateToProps = ({ currentUser }: StoreState): StateProps => {
  return {
    currentUser,
  };
};

export default withRouter(connect(mapStateToProps)(App));
