import { isAxiosError } from './components/form/utils';

export const getErrorMessage = (input: unknown): string => {
  if (typeof input === 'string') {
    return input;
  }

  if (isAxiosError(input)) {
    if (input.response?.data) {
      if (
        input.response.data.message &&
        typeof input.response.data.message === 'string'
      ) {
        return input.response.data.message;
      }

      if (
        input.response.data.detail &&
        typeof input.response.data.detail === 'string'
      ) {
        return input.response.data.detail;
      }
    }
  }

  if (input instanceof Error && input.message) {
    return input.message;
  }

  return 'Something went wrong';
};
