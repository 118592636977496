import React, { Suspense } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Container } from '@dabapps/roe';

import { StoreState } from './store/types';
import { getFormulizeProps } from './utils/formulize';
import FormulizeLazy from './formulize/lazy';

type StateProps = Pick<StoreState, 'currentUser'>;

type RouteProps = RouteComponentProps<{}>;

type Props = RouteProps & StateProps;

const Export = ({ currentUser }: Props) => {
  return (
    <Container fluid>
      <Suspense fallback={<p>Loading...</p>}>
        {currentUser?.client ? (
          <FormulizeLazy
            {...getFormulizeProps(currentUser.client.id)}
            readonly
          />
        ) : (
          <p className="error">Could not get current user/client</p>
        )}
      </Suspense>
    </Container>
  );
};

const mapStateToProps = ({ currentUser }: StoreState): StateProps => {
  return {
    currentUser,
  };
};

export default withRouter(connect(mapStateToProps)(Export));
