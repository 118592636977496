import { AxiosResponse } from 'axios';

import { GET_CURRENT_USER, LOGOUT } from '^/auth/actions';
import { CurrentUser } from '^/auth/types';
import { Action } from '^/common/types';
import { COMPLETE_FORM } from '^/form/actions';

export const currentUser = (
  state: null | CurrentUser = null,
  action: Action<AxiosResponse<CurrentUser>, {}>
): null | CurrentUser => {
  switch (action.type) {
    case GET_CURRENT_USER.SUCCESS:
      return action.payload?.data ?? null;
    case LOGOUT.SUCCESS:
      return null;
    case COMPLETE_FORM:
      if (!state?.client) {
        return state;
      }

      return {
        ...state,
        client: {
          ...state.client,
          completed_form: true,
        },
      };
    default:
      return state;
  }
};
