import { AnyAction } from 'redux';

import { CLOSE_SIDE_BAR, TOGGLE_SIDE_BAR } from '^/navigation/actions';

// eslint-disable-next-line import/prefer-default-export
export function sideBarOpen(state = false, action: AnyAction) {
  switch (action.type) {
    case TOGGLE_SIDE_BAR:
      return !state;
    case CLOSE_SIDE_BAR:
      return false;
    default:
      return state;
  }
}
