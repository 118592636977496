import React, { ComponentType } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyProps = Record<string, any>;
type DefaultExportComponent<P extends AnyProps> = {
  default: ComponentType<P>;
};
type LazyComponent<P extends AnyProps> = Promise<DefaultExportComponent<P>>;

const MAX_ATTEMPTS = 3;

const FailedToLoad = () => (
  <>
    <p className="error">Failed to load after {MAX_ATTEMPTS} attempts.</p>
    <p>
      Please make sure that your device is connected to the internet and try
      reloading the page.
    </p>
  </>
);

const lazyRetry = <P extends AnyProps>(
  loadComponent: () => LazyComponent<P>
) => {
  const loadComponentAndRetry = (attempts: number): LazyComponent<P> => {
    return loadComponent().catch(error => {
      if (attempts < MAX_ATTEMPTS) {
        return new Promise<DefaultExportComponent<P>>(resolve => {
          window.setTimeout(() => {
            resolve(loadComponentAndRetry(attempts + 1));
            // We slow this down each time in the hope that they'll regain connection
          }, attempts * 1000);
        });
      }

      if (window.console && typeof window.console.error === 'function') {
        // eslint-disable-next-line no-console
        console.error(error);
      }

      return { default: FailedToLoad };
    });
  };

  return React.lazy(() => loadComponentAndRetry(1));
};

export { lazyRetry };
