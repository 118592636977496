import { AxiosError } from 'axios';
import { FormErrors } from 'redux-form';

import { FormError } from './types';

export const focusOnMount = (element: HTMLElement | null) => {
  if (element) {
    element.focus();
  }
};

export const isAxiosError = (error: unknown): error is AxiosError =>
  error && typeof error === 'object' && 'config' in error;

export const getReduxFormErrors = <T extends Record<string, unknown>>(
  error: FormError<T>
): FormErrors<T, string | readonly string[]> => {
  if (!error) {
    return {
      _error: 'Something went wrong',
    };
  }

  if (typeof error === 'string') {
    return {
      _error: error,
    };
  }

  if (error instanceof Error) {
    if (isAxiosError(error)) {
      if (error.response?.data) {
        if (typeof error.response.data === 'string') {
          return {
            _error: error.message,
          };
        }

        if (typeof error.response.data === 'object') {
          if ('non_field_errors' in error.response.data) {
            return {
              ...error.response.data,
              _error: error.response.data.non_field_errors,
            };
          }

          if ('detail' in error.response.data) {
            return {
              ...error.response.data,
              _error: error.response.data.detail,
            };
          }

          if (error.response.data) {
            return error.response.data;
          }
        }
      }
    }

    if (typeof error.message === 'string') {
      return {
        _error: error.message,
      };
    }

    return {
      _error: error.toString(),
    };
  }

  return error;
};
