import { OpenModalAction, ModalProps } from './types';
import { MODAL_MAP } from './map';

export const OPEN_MODAL = 'OPEN_MODAL';
export const openModal = <T extends keyof typeof MODAL_MAP>(
  kind: T,
  props: ModalProps<T>
): OpenModalAction<T> => ({
  type: OPEN_MODAL,
  payload: {
    kind,
    props,
  },
});

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const closeModal = () => ({
  type: CLOSE_MODAL,
});
