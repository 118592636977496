import { format, differenceInYears } from 'date-fns';

import { FormulizeProps } from '^/formulize/form/index';

const MATCHES_DATE = /\d{4}-\d\d-\d\d/;

export const getFormulizeProps = (clientId: string): FormulizeProps => {
  const dateNow = new Date();

  return {
    schemaURL: `/api/form/schema/${clientId}/`,
    submitURL: `/api/form/response/${clientId}/`,
    specialContext: {
      'user:user_login': 'Firm name',
      today: format(dateNow, 'yyyy-MM-dd'),
      date_dmy: format(dateNow, 'dd/MM/yyyy'),
      user_agent: window.navigator.userAgent,
      PAGENO: '', // Not used
      save_link: '', // Not used
      save_email_input: '', // Not used
      save_email: '', // Not used
      form_title: '', // Not used
      admin_email: '', // Not used
      all_fields: '', // Not used
    },
    customValidation: {
      over18: date => {
        if (typeof date === 'string' && MATCHES_DATE.test(date)) {
          return differenceInYears(new Date(), new Date(date)) > 18
            ? null
            : 'Please check your date of birth - you need to be 18 years of age to instruct us';
        }

        return null;
      },
      nonAlphaNumeric: text => {
        if (typeof text === 'string') {
          const regexNonAlphaNumeric = /[^a-zA-Z\d\s:]/;
          return regexNonAlphaNumeric.test(text)
            ? 'Please do not use special characters in your memorable word. Use numbers and letters only'
            : null;
        }
        return null;
      },
    },
  };
};
