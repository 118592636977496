import React, { memo, useCallback } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  SpacedGroup,
  Button,
} from '@dabapps/roe';

import { useWithRequestState } from '^/common/hooks';

export interface ConfirmProps {
  title: string;
  message: string;
  primaryButtonClassName?: string;
  onConfirm: () => Promise<unknown>;
  closeModal: () => void;
}

const noop = () => null;

const Confirm = ({
  title,
  message,
  primaryButtonClassName = 'error',
  onConfirm,
  closeModal,
}: ConfirmProps) => {
  const onConfirmWrapper = useCallback(() => {
    return onConfirm().then(closeModal);
  }, [onConfirm, closeModal]);

  const [loading, error, onClickConfirm] = useWithRequestState(
    onConfirmWrapper
  );

  return (
    <Modal small onClickOutside={noop}>
      <ModalHeader>
        <h5>{title}</h5>
      </ModalHeader>

      <ModalBody>
        <p>{message}</p>
        {loading && <p>Loading...</p>}
        {error && <p className="error">{error}</p>}
      </ModalBody>

      <ModalFooter>
        <SpacedGroup block className="margin-vertical-large">
          <Button disabled={loading} onClick={closeModal}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={onClickConfirm}
            className={primaryButtonClassName}
          >
            Confirm
          </Button>
        </SpacedGroup>
      </ModalFooter>
    </Modal>
  );
};

export default memo(Confirm);
