import React, { memo, useCallback, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  SpacedGroup,
  Button,
  FormGroup,
  Row,
  Column,
} from '@dabapps/roe';

import { LawFirm } from '^/law-firms/types';
import { Client } from '^/clients/types';
import { useWithRequestState } from '^/common/hooks';

export interface FormProps {
  onConfirm: (selectedValue: string) => Promise<unknown>;
  closeModal: () => void;
  item: Client;
  lawFirms: readonly LawFirm[] | null;
  lawFirmsLoading: boolean;
  getLawFirmsError: string | null;
}

const noop = () => null;

const SwitchFirmForm = ({
  onConfirm,
  closeModal,
  item,
  lawFirms,
}: FormProps) => {
  const [selectedValue, setSelectedValue] = useState('');

  const onConfirmWrapper = useCallback(() => {
    return onConfirm(selectedValue).then(closeModal);
  }, [onConfirm, closeModal, selectedValue]);

  const [loading, error, onClickConfirm] = useWithRequestState(
    onConfirmWrapper
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedValue(e.target.value);
    },
    []
  );

  return (
    <Modal small onClickOutside={noop}>
      <ModalHeader>
        <h5>Switch law firm</h5>
      </ModalHeader>

      <ModalBody>
        {loading && <p>Loading...</p>}
        {error && <p className="error">{error}</p>}
        <FormGroup block>
          <Row className="padding-base">
            <Column md={4}>
              <strong>Client Name</strong>
            </Column>
            <Column md={8}>
              {item.first_name} {item.last_name}
            </Column>
          </Row>
          <Row className="padding-base">
            <Column md={4}>
              <strong>Current law firm</strong>
            </Column>
            <Column md={8}>{item.law_firm}</Column>
          </Row>
          <Row className="padding-base">
            <Column md={4}>
              <label>
                <strong>New law firm</strong>
              </label>
            </Column>
            <Column md={8}>
              <select
                placeholder="Select firm"
                name="lawFirmId"
                value={selectedValue}
                onChange={handleChange}
              >
                <option value="" hidden>
                  Select firm
                </option>
                {lawFirms &&
                  lawFirms
                    .filter(choice => choice.name !== item.law_firm)
                    .map(lawfirm => (
                      <option key={lawfirm.id} value={lawfirm.id}>
                        {lawfirm.name}
                      </option>
                    ))}
              </select>
            </Column>
          </Row>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <SpacedGroup block className="margin-vertical-large">
          <Button disabled={loading} onClick={closeModal}>
            Cancel
          </Button>
          <Button
            disabled={loading || !selectedValue}
            onClick={onClickConfirm}
            className="primary"
          >
            Update
          </Button>
        </SpacedGroup>
      </ModalFooter>
    </Modal>
  );
};

export default memo(SwitchFirmForm);
