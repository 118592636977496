import { makeAsyncActionSet, requestWithConfig } from '@dabapps/redux-requests';
import { push } from 'connected-react-router';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { closeSideBar } from '^/navigation/actions';
import { StoreState } from '^/store/types';
import {
  LoginFormData,
  ForgotPasswordFormData,
  ResetPasswordFormData,
  RegistrationData,
} from './types';

export const LOGOUT = makeAsyncActionSet('LOGOUT');
export const logout = () => (
  dispatch: ThunkDispatch<StoreState, unknown, AnyAction>
) =>
  dispatch(
    requestWithConfig(
      LOGOUT,
      { url: '/api/auth/logout/', method: 'POST' },
      { shouldRethrow: () => true }
    )
  )
    .then(() => {
      dispatch(closeSideBar());
      dispatch(push('/'));
    })
    .catch(() => null);

export const GET_CURRENT_USER = makeAsyncActionSet('GET_CURRENT_USER');
const getCurrentUserAndThrow = requestWithConfig(
  GET_CURRENT_USER,
  {
    url: '/api/auth/user/',
  },
  { shouldRethrow: () => true }
);

const LOGIN = makeAsyncActionSet('LOGIN');
export const loginAndThrow = (data: Partial<LoginFormData>) => (
  dispatch: ThunkDispatch<StoreState, unknown, AnyAction>
) =>
  dispatch(
    requestWithConfig(
      LOGIN,
      {
        url: '/api/auth/login/',
        method: 'POST',
        data,
      },
      { shouldRethrow: () => true }
    )
  ).then(() => dispatch(getCurrentUserAndThrow));

const REGISTER = makeAsyncActionSet('REGISTER');
export const registerAndThrow = (data: RegistrationData) => (
  dispatch: ThunkDispatch<StoreState, void, AnyAction>
) =>
  dispatch(
    requestWithConfig(
      REGISTER,
      {
        url: '/api/auth/registration/',
        method: 'POST',
        data,
      },
      { shouldRethrow: () => true }
    )
  ).then(() => {
    dispatch(push('/register-email-sent'));
  });

const FORGOT_PASSWORD = makeAsyncActionSet('FORGOT_PASSWORD');
export const forgotPasswordAndThrow = (
  data: Partial<ForgotPasswordFormData>
) => (dispatch: ThunkDispatch<StoreState, void, AnyAction>) =>
  dispatch(
    requestWithConfig(
      FORGOT_PASSWORD,
      {
        url: '/api/auth/password/reset/',
        method: 'POST',
        data,
      },
      { shouldRethrow: () => true }
    )
  ).then(() => {
    dispatch(push('/forgot-password-email-sent'));
  });

const RESET_PASSWORD = makeAsyncActionSet('RESET_PASSWORD');
export const resetPasswordAndThrow = (data: Partial<ResetPasswordFormData>) => (
  dispatch: ThunkDispatch<StoreState, void, AnyAction>
) =>
  dispatch(
    requestWithConfig(
      RESET_PASSWORD,
      {
        url: '/api/auth/password/reset/confirm/',
        method: 'POST',
        data,
      },
      { shouldRethrow: () => true }
    )
  )
    .then(() => dispatch(getCurrentUserAndThrow))
    .then(() => dispatch(push('/')));
