import { createReducer } from '@dabapps/redux-create-reducer';
import { AxiosResponse } from 'axios';

import { MailingListUser } from './types';
import { DjangoList } from '^/common/types';
import { GET_MAILING_LIST, CLEAR_MAILING_LIST } from './actions';

export interface GetMailingListSuccessAction {
  type: string;
  payload: AxiosResponse<DjangoList<MailingListUser>>;
}

export const mailingList = createReducer(
  {
    [GET_MAILING_LIST.SUCCESS]: (
      _state: readonly MailingListUser[] | null,
      action: GetMailingListSuccessAction
    ) => action.payload.data.results,
    [CLEAR_MAILING_LIST]: () => null,
  },
  null
);
