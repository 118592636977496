import * as yup from 'yup';

import {
  CurrentUser,
  CurrentUserClient,
  CurrentUserLawfirmLogo,
} from '^/auth/types';

const getTemplateJson = <T>(elementId: string, schema: yup.Schema<T>) => {
  const element = document.getElementById(elementId);

  if (!element) {
    throw new Error(`Could not find JSON script with ID: ${elementId}`);
  }

  const json: T = JSON.parse(element.textContent || '');

  return schema.validateSync(json);
};

const CURRENT_USER_SCHEMA = yup
  .object()
  .nullable()
  .shape<CurrentUser>({
    id: yup.string(),
    email: yup.string(),
    is_staff: yup.boolean(),
    client: yup
      .object()
      .nullable()
      .shape<CurrentUserClient>({
        completed_form: yup.boolean(),
        id: yup.string(),
      }),
    lawfirm_logo: yup
      .object()
      .nullable()
      .shape<CurrentUserLawfirmLogo>({
        id: yup.string(),
        created: yup.string(),
        modified: yup.string(),
        file_key: yup.string(),
        filename: yup.string(),
        file_path: yup.string(),
      }),
  });

// eslint-disable-next-line import/prefer-default-export
export const CURRENT_USER = getTemplateJson(
  'current_user',
  CURRENT_USER_SCHEMA
);
