import { createReducer } from '@dabapps/redux-create-reducer';
import { AxiosResponse } from 'axios';

import { LawFirm } from './types';
import {
  LIST_LAW_FIRMS,
  GET_LAW_FIRM,
  EDIT_LAW_FIRM,
  CLEAR_LAW_FIRM,
} from './actions';
import { DjangoList } from '^/common/types';

export interface ListLawFirmsSuccessAction {
  type: string;
  payload: AxiosResponse<DjangoList<LawFirm>>;
}

export interface GetEditLawFirmSuccessAction {
  type: string;
  payload: AxiosResponse<LawFirm>;
}

export const lawFirms = createReducer(
  {
    [LIST_LAW_FIRMS.SUCCESS]: (
      _state: readonly LawFirm[] | null,
      action: ListLawFirmsSuccessAction
    ) => action.payload.data.results,
    [CLEAR_LAW_FIRM]: () => null,
  },
  null
);

export const lawFirm = createReducer(
  {
    [GET_LAW_FIRM.SUCCESS]: (
      _state: LawFirm | null,
      action: GetEditLawFirmSuccessAction
    ) => action.payload.data,
    [EDIT_LAW_FIRM.SUCCESS]: (
      _state: LawFirm | null,
      action: GetEditLawFirmSuccessAction
    ) => action.payload.data,
  },
  null
);
