import {
  createActionSet,
  createFileUploadAction,
} from '@dabapps/django-s3-file-upload';

export const UPLOAD_FORM_FILES = createActionSet('UPLOAD_FORM_FILES');

export const uploadFormFiles = createFileUploadAction(UPLOAD_FORM_FILES, {
  shouldRethrow: () => true,
});
