import { Action } from 'redux';

import { COMPLETE_FORM, REDIRECTED } from './actions';

export const redirecting = (state = false, action: Action) => {
  switch (action.type) {
    case COMPLETE_FORM:
      return true;
    case REDIRECTED:
      return false;
    default:
      return state;
  }
};
