import { createReducer } from '@dabapps/redux-create-reducer';

import { OPEN_MODAL, CLOSE_MODAL } from './actions';
import { StoreState } from '^/store/types';
import { MODAL_MAP } from './map';
import { OpenModalAction, ModalConfig } from './types';

export const modals = createReducer(
  {
    [OPEN_MODAL]: (
      state: StoreState['modals'],
      action: OpenModalAction<keyof typeof MODAL_MAP>
    ) => [...state, action.payload],
    [CLOSE_MODAL]: (state: StoreState['modals']) => {
      const stateCopy = [...state];
      stateCopy.pop();
      return stateCopy as readonly ModalConfig<keyof typeof MODAL_MAP>[];
    },
  },
  []
);
