import {
  useEffect,
  useCallback,
  useReducer,
  useRef,
  useState,
  ChangeEvent,
} from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatch, ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';

import { getErrorMessage } from './utils';

const loadingCountReducer = (state: number, action: '+' | '-'): number => {
  if (action === '+') {
    return state + 1;
  }

  return state - 1;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useWithRequestState = <A extends readonly any[], R>(
  fn: (...args: A) => Promise<R>
): [boolean, string | null, (...args: A) => Promise<void | R>] => {
  const isMounted = useRef(true);
  const [loadingCount, adjustLoadingCount] = useReducer(loadingCountReducer, 0);
  const [error, setError] = useState<string | null>(null);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const wrappedFn = useCallback(
    (...args: A) => {
      if (isMounted.current) {
        adjustLoadingCount('+');
        setError(null);
      }

      return fn(...args)
        .then(result => {
          if (isMounted.current) {
            adjustLoadingCount('-');
          }

          return result;
        })
        .catch(err => {
          if (isMounted.current) {
            adjustLoadingCount('-');
            setError(getErrorMessage(err));
          }
        });
    },
    [fn]
  );

  return [loadingCount > 0, error, wrappedFn];
};

export function useDispatched<
  S,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  A extends readonly any[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  R extends Promise<any>
>(fn: (...args: A) => ThunkAction<R, S, unknown, AnyAction>): (...args: A) => R;
export function useDispatched<
  S,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  A extends readonly any[],
  R
>(fn: (...args: A) => AnyAction): (...args: A) => void;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDispatched<S, A extends readonly any[], R>(
  fn: (...args: A) => ThunkAction<R, S, unknown, AnyAction> | AnyAction
) {
  const dispatch = useDispatch<ThunkDispatch<S, unknown, AnyAction>>();

  const wrappedFn = useCallback(
    (...args: A) => {
      const action = fn(...args);

      if (typeof action === 'object') {
        dispatch(action);
      } else {
        return dispatch(action);
      }
    },
    [dispatch, fn]
  );

  return wrappedFn;
}

export const useOnMount = (
  fn: (...args: readonly unknown[]) => unknown
): void => {
  useEffect(() => {
    fn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useOnUnmount = (
  fn: (...arg: readonly unknown[]) => unknown
): void => {
  useEffect(
    () => () => {
      fn();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};

export const useFieldValue = () => {
  const [value, setValue] = useState('');

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value);
  }, []);

  return {
    value,
    onChange,
  };
};
