import 'core-js/es/array';
import 'core-js/es/set';
import 'core-js/es/map';
import 'whatwg-fetch';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import 'element-remove';

import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';

import App from '^/app';
import { history, store } from '^/store';

type Settings = Partial<{
  SENTRY_DSN: string;
}>;

const settingsElement = document.getElementById('settings');

const settings: Settings =
  settingsElement && settingsElement.textContent
    ? JSON.parse(settingsElement.textContent)
    : {};

Sentry.init({
  dsn: settings.SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('app')
);
