import { makeAsyncActionSet, requestWithConfig } from '@dabapps/redux-requests';
import queryString from 'query-string';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import { StoreState } from '^/store/types';
import { RETHROW_OPTIONS } from '^/common/constants';

export const LIST_CLIENTS = makeAsyncActionSet('LIST_CLIENTS');
export const listClients = (params: { page: number; search: string }) => {
  const query = queryString.stringify({
    ...params,
    search: params.search.trim() || undefined,
  });
  const prefixedQuery = query ? `?${query}` : '';

  return requestWithConfig(
    LIST_CLIENTS,
    {
      url: `/api/client/detail/${prefixedQuery}`,
    },
    RETHROW_OPTIONS
  );
};

export const DELETE_ACCOUNT = makeAsyncActionSet('DELETE_ACCOUNT');
export const deleteAccount = (id: string) =>
  requestWithConfig(
    DELETE_ACCOUNT,
    {
      url: `/api/accounts/${id}/`,
      method: 'DELETE',
    },
    RETHROW_OPTIONS
  );

export const MARK_INCOMPLETE = makeAsyncActionSet('MARK_INCOMPLETE');
export const markIncomplete = (id: string) =>
  requestWithConfig(
    MARK_INCOMPLETE,
    {
      url: `/api/form/response/${id}/`,
      method: 'PATCH',
      data: {
        complete: false,
      },
    },
    RETHROW_OPTIONS
  );

export const RESEND_EMAIL_TO_LAW_FIRM = makeAsyncActionSet(
  'RESEND_EMAIL_TO_LAW_FIRM'
);
export const resendEmailToLawFirm = (clientId: string) =>
  requestWithConfig(
    RESEND_EMAIL_TO_LAW_FIRM,
    {
      url: `/api/client/resend-email-to-lawfirm/`,
      method: 'POST',
      data: {
        client_id: clientId,
      },
    },
    RETHROW_OPTIONS
  );

export const SWITCH_FIRM = makeAsyncActionSet('SWITCH_FIRM');
export const switchFirm = (lawFirmId: string, clientId: string) => (
  dispatch: ThunkDispatch<StoreState, unknown, AnyAction>
) => {
  return dispatch(
    requestWithConfig(
      SWITCH_FIRM,
      {
        url: `/api/client/${clientId}/`,
        method: 'PATCH',
        data: {
          lawfirm: lawFirmId,
        },
      },
      RETHROW_OPTIONS
    )
  );
};
