import { makeAsyncActionSet, requestWithConfig } from '@dabapps/redux-requests';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import queryString from 'query-string';

import { StoreState } from '^/store/types';
import { MailingListFormData } from './types';

export const GET_MAILING_LIST = makeAsyncActionSet('GET_MAILING_LIST');
export const getMailingList = (lawFirmId: string) =>
  requestWithConfig(
    GET_MAILING_LIST,
    {
      url: `/api/mailing-list/?${queryString.stringify({
        lawfirm: lawFirmId,
      })}`,
    },
    { shouldRethrow: () => true }
  );

export const CLEAR_MAILING_LIST = 'CLEAR_MAILING_LIST';
export const clearMailingList = () => ({ type: CLEAR_MAILING_LIST });

export const ADD_MAILING_LIST_USER = makeAsyncActionSet(
  'ADD_MAILING_LIST_USER'
);
export const addMailingListUser = (
  lawFirmId: string,
  data: Partial<MailingListFormData>
) => (dispatch: ThunkDispatch<StoreState, unknown, AnyAction>) =>
  dispatch(
    requestWithConfig(
      ADD_MAILING_LIST_USER,
      {
        url: '/api/mailing-list/',
        method: 'POST',
        data: {
          ...data,
          lawfirm: lawFirmId,
        },
      },
      { shouldRethrow: () => true }
    )
  );

export const DELETE_MAILING_LIST_USER = makeAsyncActionSet(
  'DELETE_MAILING_LIST_USER'
);
export const deleteMailingListUser = (mailingListUserId: string) => (
  dispatch: ThunkDispatch<StoreState, unknown, AnyAction>
) =>
  dispatch(
    requestWithConfig(
      DELETE_MAILING_LIST_USER,
      {
        url: `/api/mailing-list/${mailingListUserId}/`,
        method: 'DELETE',
      },
      { shouldRethrow: () => true }
    )
  );
