import { Container, ModalRenderer } from '@dabapps/roe';
import React, { Suspense } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';

import NavBar from '^/navigation/components/nav-bar';
import SideBar from '^/navigation/components/side-bar';
import AuthenticatedLazy from './routes/authenticated-lazy';
import UnauthenticatedLazy from './routes/unauthenticated-lazy';
import { StoreState } from './store/types';
import { MODAL_MAP } from './modals/map';

type StateProps = Pick<StoreState, 'currentUser' | 'modals'>;

type RouteProps = RouteComponentProps<{}>;

type Props = RouteProps & StateProps;

const UI = (props: Props) => (
  <>
    <NavBar />
    <SideBar />
    <Container>
      <Suspense fallback={<p>Loading...</p>}>
        {props.currentUser ? <AuthenticatedLazy /> : <UnauthenticatedLazy />}
      </Suspense>
    </Container>
    <ModalRenderer
      modals={props.modals.map((modal, index) => {
        const Comp = MODAL_MAP[modal.kind];

        return <Comp key={index} {...modal.props} />;
      })}
    />
  </>
);

const mapStateToProps = ({ currentUser, modals }: StoreState): StateProps => {
  return {
    currentUser,
    modals,
  };
};

export default withRouter(connect(mapStateToProps)(UI));
