import {
  CreateEditLawFirmFormData,
  CreateEditLawFirmRequestData,
} from './types';

export const serializeLawFirmFormData = (
  data: Partial<CreateEditLawFirmFormData>
): CreateEditLawFirmRequestData => {
  return {
    ...data,
    logo: data.logo?.id,
  };
};
