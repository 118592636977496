import { createReducer } from '@dabapps/redux-create-reducer';
import { AxiosResponse } from 'axios';

import { LIST_CLIENTS } from './actions';
import { Client } from './types';
import { DjangoList } from '^/common/types';

export interface ListClientsSuccessAction {
  type: string;
  payload: AxiosResponse<DjangoList<Client>>;
}

export const clients = createReducer(
  {
    [LIST_CLIENTS.SUCCESS]: (
      _state: null | DjangoList<Client>,
      action: ListClientsSuccessAction
    ) => action.payload.data,
  },
  null
);
